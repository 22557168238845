import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DateRangeFilterWithstudentId } from '../models/DateRangeFilterWithstudentId';
import { DatepickerOptions } from 'ng2-datepicker';
import * as frLocale from 'date-fns/locale/en';
import * as moment from 'moment';
import { Router, ActivatedRoute } from "@angular/router";
import { StudentService } from '../services/student.service';
import { isNullOrUndefined } from 'util';
import { Chart } from 'chart.js';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StudentHistory } from '../models/studenthistory';
import { ToastrService } from 'ngx-toastr';
import { CommonMethods } from '../helper/Method';
import { ModulesEnum } from '../models/ModulesEnum';
import { PermissionEnum } from '../models/PermissionEmun';
@Component({
  selector: 'app-ninja-info',
  templateUrl: './ninja-info.component.html',
  styleUrls: ['./ninja-info.component.css']
})
export class NinjaInfoComponent implements OnInit {
  ninjainformation: any;
  beltactivityReport: any;
  gameactivityReport: any;
  studentHistoryData: any = [];
  IHoursData: any = [];
  IGamesData: any = [];
  flag1Data: number = 0;
  flag2Data: number = 0;
  historystarList: boolean[] = [true, true, true];
  beltActivityChart: Chart;
  compareChart: Chart;
  dateRangeFilter = new DateRangeFilterWithstudentId();
  date = new Date();
  @ViewChild('printninjainfo') printninjainfo: ElementRef;
  firstDay =
    new Date(this.date.getFullYear(), this.date.getMonth(), 1);

  lastDay =
    new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0);
  fromoptions: DatepickerOptions = {
    minYear: 1970,
    maxYear: 2099,
    displayFormat: 'MM-DD-YYYY',
    barTitleFormat: 'MM-DD-YYYY',
    dayNamesFormat: 'dd',
    firstCalendarDay: 0,
    locale: frLocale,
    maxDate: this.lastDay,
    barTitleIfEmpty: 'MM-DD-YYYY',
    placeholder: 'MM-DD-YYYY',
    addClass: 'form-control',
    addStyle: {},
    fieldId: 'searchFromDate',
    useEmptyBarTitle: false,
  };
  tooptions: DatepickerOptions = {
    minYear: 1970,
    maxYear: 2099,
    displayFormat: 'MM-DD-YYYY',
    barTitleFormat: 'MM-DD-YYYY',
    dayNamesFormat: 'dd',
    firstCalendarDay: 0,
    locale: frLocale,
    minDate: this.firstDay,
    barTitleIfEmpty: 'MM-DD-YYYY',
    placeholder: 'MM-DD-YYYY',
    addClass: 'form-control',
    addStyle: {},
    fieldId: 'searchtoDate',
    useEmptyBarTitle: false,
  };
  isNinjaEdit: boolean = false;
  studentHistory = new StudentHistory();
  studentHistoryForm: FormGroup;
  beltList: any = [];
  gameList: any = [];
  submitted = false;
  rating: number;
  currentUser;
  editninjaActivity: boolean = false;
  deleteninjaActivity: boolean = false;
  constructor(private formBuilder: FormBuilder, public route: ActivatedRoute, public _router: Router, private studentService: StudentService, private toastr: ToastrService, private _methods: CommonMethods) {
    const user = atob(sessionStorage.getItem('authData'));
    this.currentUser = JSON.parse(user);
  }

  ngOnInit() {
    let studentId = this.route.snapshot.params.id;
    if (!isNullOrUndefined(studentId)) {
      this.dateRangeFilter.StudentId = atob(studentId);
      this.dateRangeFilter.StartDate = moment(this.firstDay).format();
      this.dateRangeFilter.EndDate = moment(this.lastDay).format();
      this.dateRangeFilter.IdleHrs = 2;
      this.dateRangeFilter.SessionPerWeek = 2;
      this.getNinjaInfo();
      if (this._methods.CheckUserPermission(ModulesEnum.LogNinjaActivity, PermissionEnum.Update)) {
        this.editninjaActivity = true;
      }
      if (this._methods.CheckUserPermission(ModulesEnum.LogNinjaActivity, PermissionEnum.Delete)) {
        this.deleteninjaActivity = true;
      }
    }
    else {
      this._router.navigate['studenthistory'];
    }
    this.studentHistoryForm = this.formBuilder.group({
      BeltId: ['', Validators.required],
      GameId: ['', Validators.required],
      Grade: [''],
      Comment: [''],
    });
  }

  getNinjaInfo() {
    if (!isNullOrUndefined(this.beltActivityChart)) {
      this.beltActivityChart.destroy();
    }
    var timeFormat = 'MM/DD/YYYY';
    this.studentService.GetNinjaInfo(this.dateRangeFilter).subscribe((res: any) => {
      if (res.StatusCode === 200) {


        let result = res.Data;
        this.beltactivityReport = new Chart('beltActivityChart', {
          type: 'bar',
          data: {},
          options: {}
        });
        this.compareChart = new Chart('compareChart', {
          type: 'line',
          data: {},
          options: {}
        });


        this.ninjainformation = result.Student;
        this.beltactivityReport = result.NinjaReport;
        this.gameactivityReport = result.NinjaReport;
        this.studentHistoryData = result.StudentHistory;
        this.flag1Data = result.Flag1Count;
        this.flag2Data = result.Flag2Count;
        let sessionData = [];
        let gameData = [];
        let idelHoursData = [];
        let idealGameData = [];
        let barHrs = [];
        let barGames = [];
        let compareChartData = [];
        if (!isNullOrUndefined(this.beltactivityReport)) {
          this.beltactivityReport.forEach(element => {
            sessionData.push({ 'x': moment(element.Dates).format("MM/DD/YYYY"), 'y': element.CHours });
            gameData.push({ 'x': moment(element.Dates).format("MM/DD/YYYY"), 'y': element.CGames });
            idelHoursData.push({ 'x': moment(element.Dates).format("MM/DD/YYYY"), 'y': element.IHours });
            idealGameData.push({ 'x': moment(element.Dates).format("MM/DD/YYYY"), 'y': element.IGames });
            barHrs.push({ 'x': moment(element.Dates).format("MM/DD/YYYY"), 'y': element.SumHours });
            barGames.push({ 'x': moment(element.Dates).format("MM/DD/YYYY"), 'y': element.SumGames });
            compareChartData.push({ 'x': element.CHours, 'y': element.CGames });
          });
        }
        // if(!isNullOrUndefined(this.gameactivityReport)){
        //   this.gameactivityReport.forEach(element => {
        //     gameData.push({ 'x': moment(element.Dates).format("MM/DD/YYYY"), 'y': element.CGames });

        //   });
        // }

        let context = document.getElementById('beltActivityChart');
        this.beltactivityReport = new Chart(context, {
          //type: 'line',
          type: 'bar',
          data: {
            datasets: [{
              type: 'line',
              label: 'Session Activity',
              // backgroundColor: "rgba(255, 99, 132,0.4)",
              borderColor: "rgb(255, 99, 132)",
              fill: true,
              data: sessionData,
            }, {
              label: 'Game Activity',
              type: 'line',
              //backgroundColor: "rgba(255, 99, 132,0.4)",
              borderColor: "rgb(0,0,255)",
              fill: true,
              data: gameData,
            },
            {
              label: 'I Hrs',
              type: 'line',
              //backgroundColor: "rgba(255, 99, 132,0.4)",
              borderColor: "rgb(255,192,0)",
              fill: true,
              data: idelHoursData,
            },
            {
              label: 'I Games',
              type: 'line',
              //backgroundColor: "rgba(255, 99, 132,0.4)",
              borderColor: "rgb(0,0,0)",
              fill: true,
              data: idealGameData,
            }, {
              type: 'bar',
              label: 'Hrs',
              hidden: true,
              backgroundColor: "rgba(68,114,196)",
              borderColor: "rgb(68,114,196)",
              fill: true,
              data: barHrs,
            }, {
              label: 'Games',
              type: 'bar',
              hidden: true,
              backgroundColor: "rgba(255,0,0)",
              borderColor: "rgb(255,0,0)",
              fill: true,
              data: barGames,
            }]
          },
          options: {
            responsive: true,
            title: {
              display: true,
              text: 'Activity Report'
            },
            scales: {
              xAxes: [{
                type: 'time',
                time: {
                  parser: 'MM/DD/YYYY',
                  unit: 'minute',
                  displayFormats: {
                    'minute': 'MM/DD/YYYY',
                    'hour': 'MM/DD/YYYY'
                  }
                },
                ticks: {
                  source: 'data',
                  maxRotation: 90,
                  minRotation: 80
                },
                scaleLabel: {
                  labelString: 'Dates',
                  display: true
                }
              }],
              yAxes: [{
                type: 'linear',
                ticks: {
                  userCallback: function (tick) {
                    return tick.toString();
                  }
                },
                scaleLabel: {
                  labelString: 'Total Time',
                  display: true
                }
              }]
            }
          }
        });
        let context1 = document.getElementById('compareChart');
        this.compareChart = new Chart(context1, {
          //type: 'line',
          data: {
            datasets: [{
              type: 'line',
              label: 'Compare',
              // backgroundColor: "rgba(255, 99, 132,0.4)",
              borderColor: "rgb(0,0,0)",
              fill: true,
              data: compareChartData,
            }]
          },
          options: {
            responsive: true,
            title: {
              display: true,
              text: 'Compare'
            },
            scales: {
              xAxes: [{
                type: 'linear',
                ticks: {
                  userCallback: function (tick) {
                    return tick.toString();
                  }
                },
                scaleLabel: {
                  labelString: 'C Hours',
                  display: true
                }
              }],
              yAxes: [{
                type: 'linear',
                ticks: {
                  userCallback: function (tick) {
                    return tick.toString();
                  }
                },
                scaleLabel: {
                  labelString: 'C Games',
                  display: true
                }
              }]
            }
          }
        });
        setTimeout(() => {
          this.beltactivityReport.update();
          this.compareChart.update();
        });
      }
      else if (res.StatusCode === 404) {
        this._router.navigate(['studenthistory']);
      }
      else if (res.StatusCode === 400) {
        // this.toastr.error(res.Message, res.Data);
        this._router.navigate(['studenthistory']);
      }
      else {
        // this.toastr.error(res.Message, res.Data);
      }
    }, error => {
      this._router.navigate(['studenthistory']);
      // this.toastr.error(error, 'Updating of Student Belt Failed!');
    });
  }
  //from date change event
  fromchange(event) {
    this.tooptions = {
      minYear: 1970,
      maxYear: 2099,
      displayFormat: 'MM-DD-YYYY',
      barTitleFormat: 'MM-DD-YYYY',
      dayNamesFormat: 'dd',
      firstCalendarDay: 0,
      locale: frLocale,
      minDate: moment(this.dateRangeFilter.StartDate).toDate(),
      barTitleIfEmpty: 'MM-DD-YYYY',
      placeholder: 'MM-DD-YYYY', // HTML input placeholder attribute (default: '')
      addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
      addStyle: {}, // Optional, value to pass to [ngStyle] on the input field
      fieldId: 'searchtoDate',
      useEmptyBarTitle: false,
    }

  }
  tochange(event) {
    this.fromoptions = {
      minYear: 1970,
      maxYear: 2099,
      displayFormat: 'MM-DD-YYYY',
      barTitleFormat: 'MM-DD-YYYY',
      dayNamesFormat: 'dd',
      firstCalendarDay: 0,
      locale: frLocale,
      // minDate: this.dateRangeFilter.StartDate,
      maxDate: moment(this.dateRangeFilter.EndDate).toDate(),
      barTitleIfEmpty: 'MM-DD-YYYY',
      placeholder: 'MM-DD-YYYY', // HTML input placeholder attribute (default: '')
      addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
      addStyle: {},
      fieldId: 'searchFromDate',
      useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
    };
  }

  searchGetNinjaInfo() {
    let studentId = this.route.snapshot.params.id;
    if (!isNullOrUndefined(studentId)) {
      this.dateRangeFilter.StudentId = atob(studentId);
      this.dateRangeFilter.StartDate = moment(this.dateRangeFilter.StartDate).format();
      this.dateRangeFilter.EndDate = moment(this.dateRangeFilter.EndDate).format();
      if (isNullOrUndefined(this.dateRangeFilter.IdleHrs) || this.dateRangeFilter.IdleHrs == "") {
        this.dateRangeFilter.IdleHrs = 2;
      }
      if (isNullOrUndefined(this.dateRangeFilter.SessionPerWeek) || this.dateRangeFilter.SessionPerWeek == "") {
        this.dateRangeFilter.SessionPerWeek = 2;
      }
      this.getNinjaInfo();
    }
    else {
      this._router.navigate['studenthistory'];
    }
  }

  isNumberKey(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }
  print() {

    this.getNinjaInfo();

    let doc = new jspdf();
    doc.addHTML(this.printninjainfo.nativeElement, function () {
      console.log(this.ninjainformation.FullName);
      doc.save("NinjaInfo" + new Date().getTime() + ".pdf");
    });

  }

  editStudentHistory(item) {

    //this.ninjaTitle = "Edit Ninja Daily Log";
    this.isNinjaEdit = true;
    //this.isNinjaList = false;
    this.studentHistory = item;
    this.getBeltList();
    if (!isNullOrUndefined(item.BeltId)) {
      this.getGameListByBelt(item.BeltId)
    }

  }
  getBeltList() {
    this.beltList = [];
    this.studentService.GetBeltList().subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this.beltList = res.Data;
        if (this.studentHistory.BeltId == "" && this.beltList.length > 0) {
          this.studentHistory.BeltId = this.beltList["0"].BeltId;
        }
      }

    });
  }
  getGameList() {
    this.gameList = [];
    this.studentService.GetGameList().subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this.gameList = res.Data;
      }

    });
  }

  getGameListByBelt(beltId) {
    this.gameList = [];
    this.studentService.GetGameListByBeltId(beltId).subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this.gameList = res.Data;
      }

    });
  }
  get f() { return this.studentHistoryForm.controls; }
  onSubmit() {
    this.submitted = true;
    if (this.studentHistoryForm.invalid) {
      return;
    }
    if (this.isNinjaEdit) {
      this.studentHistory.ModifiedBy = this.currentUser.UserID;
      this.UpdateStudentHistory();
    }
  }

  CancelAdd() {
    this.isNinjaEdit = false;
    this.studentHistory = new StudentHistory;
    this.searchGetNinjaInfo();
  }

  UpdateStudentHistory() {
    this.studentService.UpdateStudentHistory(this.studentHistory).subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this.toastr.success(res.Data);
        this.isNinjaEdit = false;
        this.searchGetNinjaInfo();
      }
      else if (res.StatusCode === 404) {
        this.toastr.success(res.Data);
        this.studentHistoryData = [];
      }
      else if (res.StatusCode === 400) {
        this.toastr.error(res.Message, res.Data);
      }
      else {
        this.toastr.error(res.Message, res.Data);
      }
    }, error => {

      this.toastr.error(error, 'Editing of DailyUpdate Failed!');
    });
  }

  beltChangeEvent() {
    if (this.studentHistory.BeltId > 0) {

      this.getGameListByBelt(this.studentHistory.BeltId);
      setTimeout(() => {
        this.studentHistory.GameId = "";
      }, 500);
    }

  }
  setStar(data: any) {
    this.studentHistory.Grade = data + 1;
  }
  deleteDailyUpdate(item) {
    if (this._methods.CheckUserPermission(ModulesEnum.NinjaDailyLog, PermissionEnum.Delete)) {
      if (confirm("Are you sure to delete this item?")) {
        this.studentService.DeleteStudentHistory(item.StudentHistoryId).subscribe((res: any) => {
          if (res.StatusCode === 200) {
            this.toastr.success(res.Data);
            // let table = $('#dailylog-table').DataTable({
            //   'order': [[6, 'asc']], 'destroy': true
            // });

            // table.destroy();
            this.searchGetNinjaInfo();
          }
          else if (res.StatusCode === 404) {
            this.studentHistoryData = [];

          }
          else if (res.StatusCode === 400) {
            this.toastr.error(res.Message, res.Data);
          }
          else {
            this.toastr.error(res.Message, res.Data);
          }
        }, error => {

          this.toastr.error(error, 'Deleting DailyUpdate Failed!');
        });
      }
    }
    else {
      this.toastr.error("You don't have permission.");
    }
  }
}

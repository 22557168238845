import { Component, OnInit } from '@angular/core';
import { StudentService } from '../services/student.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { ProgramReportSearch } from '../models/ProgramReport';
import { ProgramReport } from '../models/ProgramReport';
import { DatepickerOptions } from 'ng2-datepicker';
import * as frLocale from 'date-fns/locale/en';
import * as moment from 'moment';
import { isNullOrUndefined } from 'util';
import { ExcelService } from '../services/excel.service';
import { CommonMethods } from '../helper/Method';
import { ModulesEnum } from '../models/ModulesEnum';
import { PermissionEnum } from '../models/PermissionEmun';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';


@Component({
  selector: 'app-program-reports',
  templateUrl: './program-reports.component.html',
  styleUrls: ['./program-reports.component.css']
})
export class ProgramReportsComponent implements OnInit {
  pageTitle: string = "Program Report";

  programReportData: any;
  programReportSearch = new ProgramReportSearch();
  date = new Date();
  firstDay =
    new Date(this.date.getFullYear(), this.date.getMonth(), 1);

  lastDay =
    new Date(this.date.getFullYear(), this.date.getMonth() + 3, 0);
  fromoptions: DatepickerOptions = {
    minYear: 1970,
    maxYear: 2099,
    displayFormat: 'MM-DD-YYYY',
    barTitleFormat: 'MM-DD-YYYY',
    dayNamesFormat: 'dd',
    firstCalendarDay: 0,
    locale: frLocale,
    maxDate: this.lastDay,  // Maximal selectable date
    barTitleIfEmpty: 'MM-DD-YYYY',
    placeholder: 'MM-DD-YYYY', // HTML input placeholder attribute (default: '')
    addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
    addStyle: {},
    fieldId: 'searchFromDate',
    useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };
  tooptions: DatepickerOptions = {
    minYear: 1970,
    maxYear: 2099,
    displayFormat: 'MM-DD-YYYY',
    barTitleFormat: 'MM-DD-YYYY',
    dayNamesFormat: 'dd',
    firstCalendarDay: 0,
    locale: frLocale,
    minDate: this.firstDay, // Minimal selectable date
    barTitleIfEmpty: 'MM-DD-YYYY',
    placeholder: 'MM-DD-YYYY', // HTML input placeholder attribute (default: '')
    addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
    addStyle: {}, // Optional, value to pass to [ngStyle] on the input field
    fieldId: 'searchtoDate',
    useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };


  readProgramReportsPermission: boolean = false;
  StudentList: any = [];
  EventTypeList: any = [];

  constructor(private _methods: CommonMethods, private formBuilder: FormBuilder, private studentService: StudentService, private toastr: ToastrService, private _router: Router, private excelService: ExcelService) { }


  ngOnInit() {


    this.programReportSearch.StartDate = moment(this.firstDay).format('MM-DD-YYYY');
    this.programReportSearch.EndDate = moment(this.lastDay).format('MM-DD-YYYY');
    this.programReportSearch.StudentId = 0;
    this.programReportSearch.EventCode = '';


    if (this._methods.CheckUserPermission(ModulesEnum.ManageProgramReports, PermissionEnum.Read)) {
      this.readProgramReportsPermission = true;

      this.getStudentList();
      this.getEventTypeList();


    }
  }

  //Search to get results
  getSearchResults() {
    this.programReportSearch.StartDate = moment(this.programReportSearch.StartDate).format('MM-DD-YYYY');
    this.programReportSearch.EndDate = moment(this.programReportSearch.EndDate).format('MM-DD-YYYY');
    this.getResultList();
  }

  getResultList() {


    let table = $('#programReport-table').DataTable({
      "destroy": true,
    });

    table.destroy();
    this._methods.showLoader();
    this.studentService.GetProgramReports(this.programReportSearch).subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this._methods.hideLoader();
        this.programReportData = res.Data;
        this.readProgramReportsPermission = true;
        var self = this;
        setTimeout(function () {
          $(function () {

            var table = $('#programReport-table').DataTable({
              'order': [[1, 'asc']],
              'destroy': true
            });
            // Apply the search
            table.columns().every(function () {
              var that = this;
              $('input', this.footer()).on('keyup change clear', function () {
                if (that.search() !== this['value']) {
                  that
                    .search(this['value'])
                    .draw();
                }
              });
            });


          });
        });
      }
      else if (res.StatusCode === 404) {
        this._methods.hideLoader();
        this.programReportData = [];
      }
      else {
        this._methods.hideLoader();
      }

    },
      error => {
        this._methods.hideLoader();
      });
  }

  //get Students List
  getStudentList() {
    this.StudentList = [];
    this.studentService.StudentList().subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this.StudentList = res.Data; // .filter(a => a.Status == 1);
      }

    });
  }

  //get Event Type List
  getEventTypeList() {
    this.EventTypeList = [];
    this.studentService.EventTypeList().subscribe((res: any) => {
      if (res.StatusCode === 200) {
        this.EventTypeList = res.Data;
      }

    });
  }

  //from date change event
  fromchange(event) {
    this.tooptions = {
      minYear: 1970,
      maxYear: 2099,
      displayFormat: 'MM-DD-YYYY',
      barTitleFormat: 'MM-DD-YYYY',
      dayNamesFormat: 'dd',
      firstCalendarDay: 0,
      locale: frLocale,
      minDate: moment(this.programReportSearch.StartDate).toDate(),
      barTitleIfEmpty: 'MM-DD-YYYY',
      placeholder: 'MM-DD-YYYY', // HTML input placeholder attribute (default: '')
      addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
      addStyle: {}, // Optional, value to pass to [ngStyle] on the input field
      fieldId: 'searchtoDate',
      useEmptyBarTitle: false,
    }

  }

  tochange(event) {
    this.fromoptions = {
      minYear: 1970,
      maxYear: 2099,
      displayFormat: 'MM-DD-YYYY',
      barTitleFormat: 'MM-DD-YYYY',
      dayNamesFormat: 'dd',
      firstCalendarDay: 0,
      locale: frLocale,
      // minDate: this.dateRangeFilter.StartDate,
      maxDate: moment(this.programReportSearch.EndDate).toDate(),
      barTitleIfEmpty: 'MM-DD-YYYY',
      placeholder: 'MM-DD-YYYY', // HTML input placeholder attribute (default: '')
      addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
      addStyle: {},
      fieldId: 'searchFromDate',
      useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
    };
  }


  downloadProgramReport() {
    let tempList = this.programReportData;
    for (let i = 0; i < tempList.length; i++) {
      delete tempList[i]["PId"];
    }
    this.excelService.exportAsExcelFile(tempList, 'ProgramReport');
  }



}
